import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { observer } from "mobx-react-lite"

import EntityPageHeaderLayout from "@components/layout/EntityPageHeaderLayout/EntityPageHeaderLayout"
import MainLayout from "@components/layout/MainLayout/MainLayout"
import BackButton from "@components/prototypes/BackButton"
import Container from "@components/ui/Container/Container"
import Chip from "@components/ui/Chip/Chip"
import Text from "@components/ui/Typography/Text"
import { useController, useStore } from "@store"
import LoadingCover from "@components/ui/Loader/LoadingCover"
import Skeleton from "@components/ui/Skeleton/Skeleton"
import { TabsCollection } from "@components/ui/TabsCollection"
import { IconName } from "@components/ui/Icon/IconName"
import { Option } from "@framework/types/utils"
import NotFound from "@components/ui/NotFound/NotFound"

import Overview from "./Overview"
import LinkContent from "./LinkContent"
import Attributes from "./Attributes"

import styles from "./index.module.sass"

const tabs: Option[] = [
  {
    name: "Overview",
    value: "overview",
    icon: "info" as IconName,
  },
  {
    name: "Attributes",
    value: "attributes",
    icon: "book-shelf" as IconName,
  },
  {
    name: "Linked Products",
    value: "Linked Products",
    icon: "link" as IconName,
  },
]

const ProductViewPage = observer(() => {
  const { id } = useParams()

  const {
    productComparisonStore: { productStore },
  } = useStore()
  const { productController } = useController()
  const [selectedTab, setSelectedTab] = useState("overview")

  const { productDetails, isProductDetailsLoading } = productStore

  useEffect(() => {
    if (id) {
      productController.loadMoreProductContentIds(1, 100, id || "")
      productController.getProductDetails(id)
    }
  }, [id])

  return (
    <MainLayout>
      <Container>
        <div className={styles.root}>
          <EntityPageHeaderLayout
            left={
              <BackButton>
                {isProductDetailsLoading ? (
                  <Skeleton
                    className={styles.nameSkeleton}
                    lineHeight={30}
                    minWidth={200}
                  />
                ) : (
                  productDetails?.name
                )}
              </BackButton>
            }
          />
          <LoadingCover
            isLoading={isProductDetailsLoading}
            className={styles.container}
          >
            <div className={styles.mainContent}>
              <Chip variant="rounded" color="green">
                {productDetails?.company?.name}
              </Chip>
              <Text weight="bold" variant="h1" className={styles.mainHeading}>
                {productDetails?.name}
              </Text>
              <TabsCollection
                className={styles.tabsContainer}
                defaultValue={tabs[0].name}
                onSelect={setSelectedTab}
                items={tabs}
              />
              {selectedTab.toLowerCase() === "overview" && (
                <Overview productDetails={productDetails} />
              )}
              {selectedTab.toLowerCase() === "attributes" && (
                <Attributes attributes={productDetails?.attributes || []} />
              )}
              {selectedTab === "Linked Products" && <NotFound />}
            </div>
            <LinkContent id={id} />
          </LoadingCover>
        </div>
      </Container>
    </MainLayout>
  )
})
export default ProductViewPage
